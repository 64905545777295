<script setup lang="ts">
import { SpLogo } from '@dev.smartpricing/sp-vue-components'
import { IS_PLAYGROUND_ENV } from '../constants'
import { FunctionalIds } from '../constants/funcitonalIds'
import { TranslationKey } from '~/i18n/TranslationKeys'
import { TestIds } from '../constants/TestIds'

const background = IS_PLAYGROUND_ENV() ? '' : 'background-color: #5154DB'

if (isSessionActive()) {
	await navigateTo('/calendar')
}
</script>

<template>
	<div class="relative h-dynamic w-screen" :style="background">
		<div class="fixed z-[999999] h-0 w-0" id="dropdowns-container"></div>
		<div class="flex h-full flex-col items-center justify-center gap-6 p-6">
			<div class="flex items-center" :class="IS_PLAYGROUND_ENV() ? 'justify-between' : 'justify-center'">
				<SpLogo :theme="SpLogoTheme.Light" :default="SpLogoVariant.Default" class="mb-1 w-[210px]"></SpLogo>

				<CommonText
					v-if="IS_PLAYGROUND_ENV()"
					class="font-semibold leading-10 text-main-blue-600"
					:text="'Playground' as TranslationKey"
					style="font-size: 34px"
				/>
			</div>

			<div class="w-full max-w-md rounded-md bg-white p-6 shadow-md md:p-8" :data-testid="TestIds.LoginContainer">
				<slot />
			</div>
		</div>

		<ModalManager />
		<CommonBannerManager class="absolute left-0 top-0 w-full" />
		<CommonBottomControlBarContainer />

		<div :id="FunctionalIds.TooltipContainer" class="absolute left-0 top-0 z-20" style="z-index: 99" />
		<CommonNotificationsManager />
		<PopupManager class="z-30" />
	</div>
</template>
